<template>
  <el-dialog
      title="订单款式"
      width="85%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-table
          :data="table.tableList"
          border
          max-height="700"
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :default-sort="{prop: 'id', order: 'descending'}"
          style="width: 100%">
        <el-table-column prop="createDate" label="添加时间" align="center" width="200">
          <template slot-scope="scope">
            <el-image
                style="width: 100%; height: 15vh;cursor:zoom-in;"
                @click="openStyleImages(scope.row.storeStyleId)"
                :src="'https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+scope.row.mainImage+'?imageMogr2/rquality/60'" fit="contain"/>
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="添加时间" align="center" width="200"/>
        <el-table-column prop="storeBrandName" label="品牌" align="center"/>
        <el-table-column prop="styleState" label="款式状态" align="center" >
          <template slot-scope="scope">
            <p v-if="scope.row.styleState===0" style="background-color: #dcdc9e;color: black">待付款</p>
            <p v-if="scope.row.styleState===1" style="background-color: #6b93ea;color: black">待发货</p>
            <p v-if="scope.row.styleState===2" style="background-color: #96d096;color: black">已发货</p>
            <p v-if="scope.row.styleState===3" style="background-color: #cb4646;color: black">已退款</p>
            <p v-if="scope.row.styleState===4" style="background-color: #ce8989;color: black">已取消</p>
          </template>
        </el-table-column>
        <el-table-column prop="trackingNumber" label="快递单号" align="center"/>
        <el-table-column prop="storeTypeName" label="款式类型" align="center"/>
        <el-table-column prop="storeSeriesName" label="系列名称" align="center"/>
        <el-table-column prop="storeSeriesNumberName" label="系列编号" align="center"/>
        <el-table-column prop="unitPrice" label="单价" align="center" />
        <el-table-column prop="styleNum" label="数量" align="center" />
        <el-table-column prop="discountPrice" label="折扣" align="center" />
        <el-table-column prop="amount" label="总价" align="center" />
        <el-table-column width="100" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group >
              <el-button v-if="scope.row.styleState===1" style="background-color: #a0e05b;border-color: #a0e05b;color: black"
                         size="mini" @click="inputTrackingNumber(scope.row)">发货</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "store-order-style-list",
  data() {
    return {
      dialogState: this.state,
      tenantCrop: localStorage.getItem("tenantCrop"),
      table:{
        tableList:[],
      }
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    storeOrder:{
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryOrderStyleList();
  },
  methods: {
    //查询款式
    queryOrderStyleList: function () {
      this.$axios({
          method: "GET",
          url: "/storeOrderStyle/queryList",
          params: {
            storeOrderId:this.storeOrder.id
          }
        }).then(response => {
          this.table.tableList=response.data.data
        });
    },
    openStyleImages(storeStyleId){
      this.$axios({
        method: "GET",
        url: "/storeStyleImage/queryLinkListByStyleId",
        params: {
          storeStyleId: storeStyleId
        }
      }).then(response => {
        this.$viewerApi({
          images: response.data.data,
        })
      })
    },
    //填写快递单号
    inputTrackingNumber(row){
      this.$prompt('请填写快递单号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.sendStyle(row.id,value);
      }).catch(() => {
      });
    },
    //一键发送
    sendStyle(id,value){
      this.$axios({
        method: "POST",
        url: "/storeOrderStyle/sendStyle",
        data: {
          id: id,
          storeOrderId:this.storeOrder.id,
          styleState:2,
          trackingNumber: value,
        }
      }).then(response => {
        if (response.data.code === 200){
          this.$message.success("单件款式发货成功!");
          this.queryOrderStyleList();
          return;
        }
        this.$message.error(response.data.msg);
      });
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>